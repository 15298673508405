export const themes = [
  { name: 'light', title: 'Light' },
  { name: 'dark', title: 'Dark' },
  // add new themes here, this list is used by the SelectTheme component.
];

export function getTheme(title) {
  const data = require( `./${title}` );
  //console.log( `Theme (${title}):`, data );
  return data.default;
}
