import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            //main: "#4287f5"
            //main: "#35bbe8"
            main: '#404040'
        }
    },
    overrides: {
        MuiSwitch: {
            colorSecondary: {
                "&$checked": {
                    color: 'blue'
                }
            },
            track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "#808080",
                "$checked$checked + &": {
                  // Controls checked color for the track
                  opacity: 0.7,
                  backgroundColor: "#808080"
                }
              }
        },
        MuiCssBaseline: {
            '@global': {
                  html: {
                    backgroundColor: '#eeeeee',
                    //WebkitFontSmoothing: 'auto',
                  },
            },
        },
        MuiDialog: {
            paper: {
                margin: 10
            }
        }
    }
});
export default theme;